import React from "react";
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import parse from 'html-react-parser';

import logo from "./assets/images/logo-skv-yellow.png";
import sokol_logo from "./assets/images/logo-skv-sokol.png";

class Team 
{
  name;
  coach;
  coach_photo;
  message = "";
  valid = false;
}

const TeamBox = ({birthdate, gender}) => {
  let team, year;

  if (!birthdate || !gender) {
    return("");
  }

  year = birthdate.getFullYear();

  team = calculateTeam(year);

  function calculateTeam(year) {
    team = new Team();
    if (year >= 2024) {
      team.message = "Váš potomek ještě není na světě, ale je skvělé, že na jeho sportovní budoucnost myslíte už teď."
      team.valid = false;
    } else if (year >= 2020) {
      team.message = "Vaše dítě se narodilo v roce " + year + ". Do našich týmů přijímáme děti od 5 let věku."
      team.valid = false;
    } else if (year >= 2018) {
      team.name = "Minipřípravka";
      team.coach = "Lucie Šťastná";
      team.coach_photo = "assets/stastna.jpg"
      team.message = "Minipřípravka je naše vůbec nejmladší kategorie. Ve věku od 5 let se děti seznamují nejen s florbalem, ale i s ostatními sporty. Snažíme se v nich vypěstovat pozitivní vztah k pohybu a zdravému životnímu stylu."
      team.valid = true;
    } else if (year >= 2016) {
      team.name = "Přípravka";
      team.coach = "Lucie Šťastná";
      team.coach_photo = "assets/stastna.jpg"
      team.message = "I v přípravce s florbalem kombinujeme další sporty, Vaše dítě rozhodně nebude trávit čas jen s florbalovou holí - už to ale začíná být jeho/její nejlepší kamarádka!"
      team.valid = true;
    } else if (year >= 2014) {
      if(gender === "žena") {
        team.name = "Elévky"
        team.coach = "Jonáš Sigl";
        team.coach_photo = "assets/sigl.jpg"
        team.message = "V elévkách už začíná být florbalka tím nejvěrnějším přítelem. Nezapomínáme ale v žádném případě na zdravý pohyb a dobré sportovní návyky, které děti našly už v přípravce. To jsou totiž ty správné pevné základy každého sportovce."
        team.valid = true;
      } else {
        team.name = "Elévové";
        team.coach = "Lucie Šťastná";
        team.coach_photo = "assets/stastna.jpg"
        team.message = "V elévech už začíná být florbalka tím nejvěrnějším přítelem. Nezapomínáme ale v žádném případě na zdravý pohyb a dobré sportovní návyky, které děti našly už v přípravce. To jsou totiž ty správné pevné základy každého sportovce."
        team.valid = true;
      }
    } else if (year >= 2012) {
      if(gender === "žena") {
        team.name = "Mladší žákyně"
        team.coach = "Nguyen Chi Hieu";
        team.coach_photo = "assets/nguyen.jpg"
        team.message = "V kategorii žákyň už začínáme pomalu ale jistě měřit síly s ostatními. Soutěživost, snaha být lepší a vítězit. Vítězit, ale fér!"
        team.valid = true;
      } else {
        team.name = "Mladší žáci";
        team.coach = "Petr Antuš";
        team.coach_photo = "assets/antus.jpg"
        team.message = "V kategorii žáků už začínáme pomalu ale jistě měřit síly s ostatními. Soutěživost, snaha být lepší a vítězit. Vítězit, ale fér!"
        team.valid = true;
      }
    } else {
      team.message = "Na klasický nábor už je Vaše dítě přece jen starší. To ale neznamená, že pro něj nenajdeme místo. Formulář můžete vyplnit, a když nám <strong>napíšete do poznámky</strong>, zda má Vaše dítě předchozí zkušenost s florbalem, případně s jinými sporty, my se Vám ozveme."
      team.valid = false;
    }

    return team;
  }

  if (team.valid)
  {
    return (
      <Card bg="light" className="mb-3">
          <div className="img-holder">
            <Card.Img src={team.coach_photo} className="w-100" />
          </div>
          <Card.Body>
                <Card.Title><strong>{team.name}</strong> - co Vaše dítě v této kategorii čeká?</Card.Title>
              <p>Vaše dítě se narodilo v roce {birthdate.getFullYear()}. Děti v tomto věku spadají do věkové kategorie <mark className="bg-skv-yellow">{team.name}</mark>, o kterou se spolu se svým kolektivem trenérů stará <mark className="bg-skv-yellow">{team.coach}</mark>.</p>
              {parse(team.message)}
          </Card.Body>
      </Card>
      )
  } else {
    return (
      <Card bg="light" className="mb-3">
        <Card.Body>
        <Card.Title>Do klasických náborů nespadáte. Ozvěte se nám na e-mail <a href="mailto:sekretar@skvflorbal.cz">sekretar@skvflorbal.cz</a>.</Card.Title>
        {parse(team.message)}
        </Card.Body>
      </Card>
      )    
  }
}

const Divider = ({icon}) => {
  return (
    <div className="hr-theme-slash-2">
      <div className="hr-line"></div>
      <div className="hr-icon">{icon}</div>
      <div className="hr-line"></div>
    </div>
  )
}

const AppFooter = () => {
  return (
    <section className="mt-3 pb-3 bg-secondary">
      <Container>
      <Row>
          <Col>
          <div className="text-center">
            <Image src={logo} style={{height: '150px'}} />
          </div>
          <div className="copyright text-center text-light">
            © 2021 TJ Sokol Královské Vinohrady <br />Všechna práva vyhrazena. 
          </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const NavBar = () => {
  return (
    <div  className="sticky_logo">
      <a href="https://www.skvflorbal.cz" target="_blank" alt="TJ Sokol Královské Vinohrady - florbalový oddíl" title="skvflorbal.cz - stránky florbalového oddílu TJ Sokol Královské Vinohrady" rel="noreferrer">
      <Image src={sokol_logo} className="float-left align-middle pr-3" />
      </a>
      <div>
        <p><small>TJ Sokol Královské Vinohrady<br />člen České obce Sokolské</small></p>
      </div>
    </div>
  )
}

const AppHeader = () => {
  return (
    <section className="header mb-3">
      <Container fluid>
        <Row>
          <Col className="fans">
          <h1>Florbal na Vinohradech rozpohybuje Vaše děti!</h1>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export {TeamBox, AppFooter, AppHeader, Divider, NavBar}