import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import ConsentPage from './ConsentPage';
import reportWebVitals from './reportWebVitals';


const tagManagerArgs = {
  gtmId: 'GTM-W7VCXX4'
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize('UA-56659142-4');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route path="/gdpr">
            <Consent />
          </Route>
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

function Homepage() {
  return (
    <CookiesProvider>
      <App />
    </CookiesProvider>
  );
}

function Consent() {
  return (
    <ConsentPage />
  );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
