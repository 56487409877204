import React from "react";
import ReactPixel from 'react-facebook-pixel';
import CookieConsent from "react-cookie-consent";
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import moment from 'moment';
import { Cookies, withCookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { TeamBox, AppFooter, AppHeader, Divider, NavBar } from "./AppElements"

import { Formik } from 'formik';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import YouTube  from 'react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import * as yup from 'yup';
import { fa_skv, fa_skv_shape } from './skv_icons';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';

import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import "rc-steps/assets/index.css";

library.add(fas, far, fab, fa_skv, fa_skv_shape);

// remote logging
// const _LTracker = window._LTracker || [];
//_LTracker.push({
    //'logglyKey': '885e3129-7948-4bc4-a6b3-52c8e144ff04',
    //'sendConsoleErrors': true,
    //'tag': 'loggly-jslogger'
//});

// FB pixel SETUP
const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('513021099859952', advancedMatching, options);
ReactPixel.revokeConsent();

// Form SETUP
// regexp for matching international or local phone number format
const phoneRegExp = /^\+[1-9]{1}[0-9]{11,11}$|^[0-9]{9,9}$/;

const schema = yup.object().shape({
  firstName: yup.string().required("Pole je povinné."),
  lastName: yup.string().required("Pole je povinné."),
  gender: yup.string().required("Pole je povinné."),
  email: yup.string().required("Pole je povinné.").email('Neplatná e-mailová adresa.'),
  phone: yup.string().required("Pole je povinné.").matches(phoneRegExp, 'Zadejte ve formátu s nebo bez +420.'),
});

const opts = {
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    mute: 1,
    disablekb: 1
  },
};

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      birthdate: new Date(),
      captcha_val: ""
    };
  }

  componentDidMount()
  {
    loadReCaptcha("6LcyA8EUAAAAAHWugu9n0VcCpfvd1Je4o2CILKu6", 	() => {});
  }

  verifyCallback = (recaptchaToken) => {
    this.setState({
      captcha_val: recaptchaToken
    })
  }

  updateToken = () => {
    this.recaptcha.execute();
  }

  setBirthDate(dateToSet) {
    this.setState({ 
      birthdate: new Date(dateToSet)
    })
  }

  handleSubmit = async (values, {setSubmitting, setStatus}) => {
    setSubmitting(true);

    var source;

    if (values.source === undefined) {
      source = []
    } else {
      source = values.source
    }

    const payload_registration = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      captcha: this.state.captcha_val,
      referral_code: "nabory.skvflorbal.cz",
      note: "pohlaví: " + values.gender + "\nKomentář: " + values.comment,
      birthdate: moment(this.state.birthdate).format("Y-MM-DD"),
      custom_data: [
        {
          custom_data_field_id : 8,
          value: source
        }
      ]
    }

    console.log(payload_registration);

    fetch("https://clen.skvflorbal.cz/api/v2/auth/member-registrations", {
      headers: {
        "accept": "application/json",
        "content-type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(payload_registration),
    }).then(function(response) {
      if (response.status === 204) {
         console.log("OK!");
         setStatus({sent: true, delivery: true, msg: "Váš náborový formulář byl úspěšně odeslán. Děkujeme a těšíme se na Vás!"});
         return;
      };

      return Promise.reject(response);
    }).then(function(responseData) {
      console.log(responseData);
    })
    .catch(function(error) {
      setStatus({sent: true, delivery: false, msg: "Při odeslání Vašeho náborového formuláře se něco pokazilo. Obnovte stránku a zkuste to prosím znovu. Pokud by to ani tak nešlo, zkontaktujte nas prosím na sekretar@skvflorbal.cz, případně telefonicky na +420 777 448 802."});
      console.error("Error with status code: " + error.status);
      if (error.status === 422) {
        error.json().then(json => console.warn(json.errors));
      } else if (typeof error.status !== "undefined") {
        error.json().then(json => console.warn(json.message));
      } else {
        console.error(error.toString())
      }
    });

    setSubmitting(false);
  }

  render() {
    return(
    <Formik
      validationSchema={schema}
      onSubmit={this.handleSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        gender: 'muž',
        email: '',
        birthdate: '',
        phone: '',
        comment: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        status,
        isSubmitting
      }) => (
        <div>
          <Alert variant={(status && status.delivery) ? 'success' : 'danger'} className={(status && status.sent) ? '' : 'd-none'}>
            {(status && status.delivery) ? <Alert.Heading>Děkujeme!</Alert.Heading> : <Alert.Heading>Oops!</Alert.Heading>}
            {status && status.msg}
          </Alert>
          <Form noValidate onSubmit={handleSubmit} className={(status && status.sent) ? 'd-none' : ''}>
              <ReCaptcha
                ref={ref => this.recaptcha = ref}
                sitekey="6LcyA8EUAAAAAHWugu9n0VcCpfvd1Je4o2CILKu6"
                action='registration'
                verifyCallback={this.verifyCallback}
              />
              <Form.Group  controlId="validationFormik01" className="required">
                <Form.Label>Jméno</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Jméno"
                  name="firstName"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.firstName && !errors.firstName}
                  isInvalid={!!errors.lastName}
                />
              </Form.Group>
              <Form.Group  controlId="validationFormik02" className="required">
                <Form.Label>Přijmení</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Přijmení"
                  name="lastName"
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.lastName && !errors.lastName}
                  isInvalid={!!errors.lastName}
                />
              </Form.Group>
              <Form.Group  controlId="validationFormik02" className="required">
                <Form.Label>Pohlaví</Form.Label>
                <Form.Control  as="select"
                  size="sm"
                  type="text"
                  placeholder="Pohlaví"
                  name="gender"
                  value={values.gender}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.gender && !errors.gender}
                  isInvalid={!!errors.gender}
                  >
                    <option>muž</option>
                    <option>žena</option>
                  </Form.Control>
              </Form.Group>
              <Form.Group controlId="validationFormik03" className="required">
                <Form.Label>Kontaktní e-mail</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="E-mail"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationFormik04" className="required">
                <Form.Label>Kontaktní telefon</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Kontaktní telefon"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="required">
                <Form.Label>Datum narození</Form.Label>
                <DatePicker required
                  wrapperClassName="form-control" 
                  className="form-control form-control-sm" 
                  name="birthdate"
                  onChange={this.setBirthDate.bind(this)}
                  value={this.state.birthdate}
                  calendarIcon={<FontAwesomeIcon icon={['fas', 'calendar']} />}
                  format="d.M.yyyy"
                  clearIcon={null}
                  />
              </Form.Group>
              <TeamBox birthdate={this.state.birthdate} gender={values.gender} />
              <Form.Group  controlId="validationFormik05" className="required">
                <Form.Label>Odkud jste se o SKV dozvěděli?</Form.Label>
                <Form.Control  as="select"
                  size="sm"
                  type="text"
                  name="source"
                  value={values.source}
                  multiple={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.source && !errors.source}
                  isInvalid={!!errors.source}
                  >
                    <option value="4c21b689-d772-471f-8f30-42e627680caf">Dítě baví florbal ve škole</option>
                    <option value="ac1afdd4-e751-4ef7-b9b5-618cfa69a4a1">Dítě bavil kroužek florbalu provozovaný klubem</option>
                    <option value="06a14b9a-2149-4504-ab87-593848f7ef50">Dítě bavila ukázková hodina florbalu pořádaná klubem</option>
                    <option value="9f3739e8-0666-4de5-ba7a-06122624f262">Dítě bavila florbal na sportovním festivalu</option>
                    <option value="58a702db-80b5-4817-b73f-cd5173360715">Doporučení od známých</option>
                    <option value="cdb4b6cf-b82b-4197-8efa-84826765b1b5">Internetová reklama</option>
                    <option value="d10bbbf4-9490-4657-8024-1d3406897df1">Náborový plakát, leták nebo banner</option>
                    <option value="25e3652d-66ea-46cf-a81b-3ad57d4abd72">Jsem bývalý či aktivní člen SKV</option>
                    <option value="6e0d3735-1172-4cad-a4df-91e828f53568">Jiné mé dítě je již členem SKV</option>
                  </Form.Control>
              </Form.Group>
              <Form.Group controlId="validationFormik06">
                <Form.Label>Poznámka</Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  type="text"
                  placeholder="Napište jakoukoliv další poznámku, kterou nám chcete v souvislosti s náborem sdělit."
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                />
              </Form.Group>
            <Button type="submit" variant="skv-yellow" size="xl" block disabled={isSubmitting}>Odeslat</Button>
          </Form>
        </div>
      )}
    </Formik>
  )}
}

const ContactForm = () => {
  return (
<section className="contact-form">
    <Container>
      <Row>
        <Col className="mx-auto">
          <h2>Pohyb, sport, parta, zážitky = SKV Florbal!</h2>
          <p className="lead">Dopřejte vašim dětem zdravý vztah k pohybu a partu kamarádů na celý život.</p>
          <p><strong>SKV</strong>, to je <strong>TJ Sokol Královské Vinohrady</strong> - již <mark className="bg-skv-yellow">25 let</mark> fungující florbalový klub pro <mark className="bg-primary text-white">kluky</mark> i <mark className="bg-girls">holky</mark>, který navíc ve výkonnostních kategoriích hraje nejvyšší soutěže v České republice.</p>
          <p>Pohyb a aktivita.</p>
          <p>Sport, který děti baví. </p>
          <p>Parta, ve které se budou cítit jako ve druhé rodině, a která jim vydrží na celý život. </p>
          <p>Na florbalových Vinohradech nabízíme <mark className="bg-skv-yellow">lidský přístup</mark>, <mark className="bg-skv-yellow">obětavé trenéry</mark>, <mark className="bg-skv-yellow">spoustu zábavy</mark> a <mark className="bg-skv-yellow">lásku k pohybu</mark>. To vše v našem unikátním areálu <mark className="bg-skv-yellow">v centru Prahy</mark>.</p>
          <Divider icon={<FontAwesomeIcon icon={['fac', 'skv-shape']} size="xs" />} />
          <div className="videoWrapper">
            <YouTube 
            videoId="R8caHjircU4"
            opts={opts} />
          </div>
          <Divider icon={<FontAwesomeIcon icon={['fac', 'skv-shape']} size="xs" />} />
          <h2>Jak to u nás probíhá?</h2>
          <p>Vše je <mark className="bg-skv-yellow">nezávazné</mark> a můžete si to vyzkoušet. <mark className="bg-skv-yellow">Nebudete kupovat zajíce v pytli</mark> - nebudeme Vás nutit předem platit za něco, co Vám a Vašemu dítěti nebude vyhovovat. <mark className="bg-skv-yellow">První měsíc u nás máte zdarma</mark>.</p>
          <Stepper orientation="vertical">
            <Step active>
              <StepLabel
                icon={<FontAwesomeIcon icon={['fas', 'pencil-alt']} size="lg" />}>
                  Vyplníte formulář
                </StepLabel>
                <StepContent>
                  Prvním krokem je vyplnění našeho <mark className="bg-secondary text-white">nezávazného formuláře</mark>
                </StepContent>
            </Step>
            <Step active>
              <StepLabel
                icon={<FontAwesomeIcon icon={['fas', 'comments']} size="lg" />}>
                  Ozveme se Vám
                </StepLabel>
                <StepContent>
                  Po vyplnění formuláře se Vám <mark className="bg-secondary text-white">ozveme s dalšími instrukcemi</mark>
                </StepContent>
            </Step>
            <Step active>
              <StepLabel
                icon={<FontAwesomeIcon icon={['fas', 'play']} size="lg" />}>
                  Vyzkoušíte si trénink
                </StepLabel>
                <StepContent>
                  Vaši žádost zpracujeme a <mark className="bg-secondary text-white">pozveme Vás na trénink</mark>, kde si Vaše dítě vše vyzkouší. <mark className="bg-secondary text-white">Vybavení Vám zapůjčíme</mark>, stačí sportovní obuv a oblečení!
                </StepContent>
            </Step>
            <Step active>
              <StepLabel
                icon={<FontAwesomeIcon icon={['fas', 'trophy']} size="lg" />}>
                  Hrajte florbal v SKV
                </StepLabel>
                <StepContent>
                <mark className="bg-secondary text-white">Prvních 14 dní u nás máte na vyzkoušení</mark> - zkuste, zda je SKV pro Vás i Vaše dítě to pravé
                </StepContent>
            </Step>
          </Stepper>
          <Divider icon={<FontAwesomeIcon icon={['fac', 'skv-shape']} size="xs" />} />
          <h2>Náborový formulář</h2>
          <p>Máte zájem o florbalový nábor do SKV? Stačí vyplnit následující <mark className="bg-secondary text-white">nezávazný náborový formulář</mark> a my se Vám ozveme!</p>
          <RegistrationForm />
        </Col>
      </Row>
      </Container>
      </section>
  )
}

const ContactFooter = () => {
  return (
    <section className="mt-3">
      <Container>
      <Row>
          <Col>
            <p className="small">Údaje z tohoto formuláře nám slouží pouze pro účely zpracování Vašeho zájmu o nábor.</p>
            <p className="small">V případě, že se naším členem Vaše dítě nakonec nestane, údaje smažeme (v takovém případě je budeme uchovávat po dobu 12 měsíců). V případě, že se Vaše dítě stane členem našeho klubu, budeme tyto údaje uchovávat a zpracovávat pokud nepomine zákonný důvod, kterým je (mimo jiné) evidence členské základny klubu a úkony spojené s účastí předmětné osoby v utkáních organizovaných v rámci soutěží Českého florbalu a jiných sportovních utkáních.</p>
            <p className="small">Kompletní Informace o zpracování osobních údajů naleznete <a href="./gdpr" target="_blank">zde</a>.</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    console.log("About to construct App.");
  }

  componentDidMount() {
    document.title = "SKV - Florbalové nábory"
    const { cookies } = this.props;
    // test for Consent Cookie
    if(cookies.get('SKV_Nabory_Consent') === "true")
    {
      console.log("Cookie consent is already set. Logging page view.");
      ReactPixel.grantConsent();
      ReactPixel.pageView();
    } else {
      console.log("Cookie consent is not set, waiting for user to grant it.");
    }
  }

  render() {
    return (
      <div>
        <NavBar />
        <AppHeader />
        <ContactForm />
        <ContactFooter />
        <AppFooter />
        <CookieConsent 
        buttonText="Beru na vědomí"
        cookieName="SKV_Nabory_Consent"
        expires={1}
        onAccept={() => {
          ReactPixel.grantConsent();
          ReactPixel.pageView();
          console.log('FB pixel consent granted, page view logged.');
        }}>Na těchto stránkách užíváme cookies. Proti jejich sběru na základě oprávněného zájmu se můžete odvolat na gdpr@skvflorbal.cz, nebo budeme rádi, když nám jejich užívání odsouhlasíte. <a href="./gdpr">Více o cookies</a></CookieConsent>
      </div>
    )
  }
}

export default withCookies(App);