import React from "react";
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';

class ConsentPage extends React.Component {

  componentDidMount() {
    document.title = "SKV - Florbalové nábory"
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
          <Jumbotron>
            <h1>Informace o zpracování osobních údajů a používání cookies</h1>
            <p class="lead">Na stránce <a href="./">nabory.skvflorbal.cz</a> zpracováváme osobní údaje a používáme soubory cookies.</p>
          </Jumbotron>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title as="h3">Informace o provozovateli stránek</Card.Title>
              <p>Tělocvičná jednota Sokol Praha - Královské Vinohrady (sídlem Polská 2400/1a, 120 00 Praha 2, IČ 00200191, zapsaná v obchodním rejstříku u Městského soudu v Praze - spisová značka L27745), dále <mark className="bg-skv-yellow">TJ SKV</mark>, je <mark className="bg-skv-yellow">provozovatelem</mark> webových stránek <a href="./">nabory.skvflorbal.cz</a></p>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title as="h3">Informace o používání cookies</Card.Title>
              <p>TJ SKV je <mark className="bg-skv-yellow">správcem osobních údajů</mark> a využívá na svých webových stránkách <mark className="bg-secondary text-light">soubory cookies</mark> za následujícím účelem:</p>
              <ul>
                <li><mark className="bg-secondary text-light">měření návštěvnosti</mark> webových stránek, vytváření statistik návštěvnosti</li>
                <li>zajištění <mark className="bg-secondary text-light">fungování</mark> webových stránek</li>
                <li><mark className="bg-secondary text-light">optimalizace reklam</mark> na sociálních sítích (např. Facebook)</li>
              </ul>
              <h4>Zpracování osobních údajů</h4>
              <p>Sběr cookies za účelem uvedeným v předchozím odstavci lze považovat za zpracování osobních údajů. Takové zpracování je možné na <mark className="bg-secondary text-light">základě zákonného důvodu</mark> a umožňuje ho čl. 6 odst. 1 písm. f z Nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dříve i dále jen „Nařízení“).</p>
              <h4>Užívání stránek v anonymním režimu</h4>
              <p>Webové stránky můžete používat i v režimu, který neumožňuje sbírání údajů o návštěvnících. Tento režim je možné nastavit v prohlížeči. Proti sběru údajů lze na základě čl. 21 v Nařízení také vznést námitku, a to jejím zasláním e-mailem na gdpr@skvflorbal.cz. Vaši námitku vyhodnotíme nejpozději do 30 dnů od chvíle, kdy ji obdržíme. Cookies nezbytné pro funkčnost webu budou uchovány pouze po dobu nezbytně nutnou pro fungování webu.</p>
              <h4>Další zpracovatelé osobních údajů</h4>
              <p>Vezměte prosím na vědomí, že shromážděné cookies soubory jsou zpracovány i <mark className="bg-skv-yellow">dalšími zpracovateli</mark>:</p>
              <ul>
                <li>Google Analytics, AdWords, Google Tag Manager, Google Inc., USA</li>
                <li>Facebook Inc., California, USA</li>
              </ul>
              <p>Výše uvedení zpracovatelé osobních údajů dále s cookies nakládají v souladu s jejich smluvními podmínkami, které najdete na jejich webových stránkách.</p>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title as="h3">Informace o sběru a zpracování osobních údajů</Card.Title>
              <p>TJ SKV je <mark className="bg-skv-yellow">správcem osobních údajů</mark> a využívá na svých webových stránkách <mark className="bg-secondary text-light">osobní údaje</mark> za následujícím účelem:</p>
              <ul>
                <li><mark className="bg-secondary text-light">zpracování zájmu návštěvníka o nábor</mark> do oddílu florbalu TJ SKV</li>
                <li><mark className="bg-secondary text-light">evidence členské základny</mark> oddílu florbalu TJ SKV (v případě vstupu návštěvníka do oddílu florbalu)</li>
              </ul>
              <h4>Zpracování osobních údajů</h4>
              <p>Sběr údajů za účelem uvedeným v předchozím odstavci lze považovat za zpracování osobních údajů. Takové zpracování je možné na <mark className="bg-secondary text-light">základě zákonného důvodu</mark> a umožňuje ho čl. 6 odst. 1 písm. f z Nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dříve i dále jen „Nařízení“).</p>
              <h4>Jaké údaje shromažďujeme</h4>
              <p>Za účelem zpracování zájmu návštěvníka o nábor shromažďujeme následující osobní údaje:</p>
              <ul>
                <li>jméno a přijmení</li>
                <li>pohlaví</li>
                <li>kontaktní e-mail</li>
                <li>kontaktní telefon</li>
                <li>datum narození</li>
              </ul>
              <h4>Kde údaje uchováváme</h4>
              <p>Údaje jsou uchovány v <mark className="bg-secondary text-light">Členské sekci TJ SKV</mark> - systému, sloužící jako centrální evidence členské základny TJ SKV a pro evidenci registrací (tj. žádostí o nábor).</p>
              <h4>Jak dlouho údaje uchováváme</h4>
              <p>Doba uchování údajů se liší dle stavu provedeného náboru:</p>
              <ul>
                <li>pokud se zájemce <mark className="bg-secondary text-light">stane členem</mark> florbalového oddílu TJ SKV - údaje uchováváme po dobu trvání zákonného důvodu, kterým je evidence členské základny klubu a úkony spojené s účastí zájemce v utkáních organizovaných v rámci soutěží Českého florbalu a jiných sportovních utkáních</li>
                <li>pokud se zájemce <mark className="bg-secondary text-light">nestane členem</mark> florbalového oddílu TJ SKV - údaje uchováváme po dobu nejdéle 12 měsíců od jejich poskytnutí</li>
              </ul>
              <h4>Vaše práva</h4>
              <p>Po TJ SKV, jakožto správci osobních údajů, máte právo požadovat následující:</p>
              <ul>
                <li><mark className="bg-secondary text-light">informace</mark> o zpracovávaných osobních údajích</li>
                <li><mark className="bg-secondary text-light">kopii</mark> zpracovávaných osobních údajů</li>
                <li><mark className="bg-secondary text-light">úpravu</mark> zpracovávaných osobních údajů</li>
                <li><mark className="bg-secondary text-light">výmaz</mark> zpracovávaných osobních údajů</li>
              </ul>
              <p>Výše uvedené úkony můžete vyžádat zasláním zprávy na gdpr@skvflorbal.cz.</p>
            </Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}



export default ConsentPage;